import React, { useEffect, useState } from "react";
import Image from "next/image";
import HeaderNav from "@/components/Header/HeaderNav";
import process from "process";
import { useQuery } from "react-query";
import { queryKeys } from "@/queries/queryKeys";
import axios from "axios";
import Link from "next/link";
import classNames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useRouter } from "next/router";
import { SVGLinkArrow } from "@/images/svg/svg-icons";

export default function LandingHeader({
  applyNowText,
}: {
  applyNowText: string;
}) {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const url = `https://api-us.storyblok.com/v2/cdn/stories/header?token=${process.env.NEXT_PUBLIC_STORYBLOK_API_KEY}&version=published`;
  const { data, isLoading } = useQuery(queryKeys.header, () => axios.get(url));
  const story = data?.data?.story?.content;

  const route = useRouter();
  const [isHomepage, setIsHomepage] = useState(false);
  useEffect(() => {
    if (route.pathname === "/") {
      setIsHomepage(true);
    } else {
      setIsHomepage(false);
    }
  }, [route]);

  function onServices() {
    setIsShowMenu(false);
    route.push("/").then(() => {});
    setTimeout(() => {
      const element = document.getElementById("services");
      if (element) {
        const targetElTop = element?.offsetTop;
        window.scrollTo({
          top: targetElTop - 79,
          behavior: "smooth",
        });
      }
    }, 500);
  }
  return (
    <>
      <header
        className={classNames(
          "landing-header fixed top-0 w-full lg:h-[87px] md:h-[80px] h-[64px] bg-white md:py-4 py-2 lg:px-[72px] md:px-11 pl-4 pr-0 flex items-center justify-between z-20"
        )}
      >
        {!isLoading && (
          <>
            <div className="w-10 min-w-10 overflow-hidden">
              <Link href={"/"}>
                <Image
                  src={story.logo}
                  width={100}
                  height={100}
                  className="object-contain object-center"
                  alt="Logo"
                />
              </Link>
            </div>
            <HeaderNav
              story={story}
              applyNowText={applyNowText}
              isShowMenu={isShowMenu}
              setIsShowMenu={setIsShowMenu}
              isHomepage={isHomepage}
              onServices={onServices}
            />
          </>
        )}
      </header>
      {!isLoading && (
        <div
          className={classNames(
            "md:hidden flex flex-col fixed h-screen w-full left-0 bg-white z-10 pt-[80px] px-4 header-menu",
            isShowMenu && "show-menu"
          )}
        >
          <ul className="flex flex-col mt-[48px] w-full gap-y-10 px-4 menu">
            {!isHomepage ? (
              <li>
                <a
                  onClick={() => onServices()}
                  className="m-0 whitespace-nowrap cursor-pointer"
                >
                  Services
                </a>
              </li>
            ) : (
              <li>
                <AnchorLink
                  href={`#services`}
                  className="m-0 whitespace-nowrap cursor-pointer"
                  offset={80}
                >
                  Services
                </AnchorLink>
              </li>
            )}
            {story.header_nav.map((i: any) => (
              <li key={i._uid}>
                <Link href={i.link.url} legacyBehavior>
                  <a
                    className={classNames(
                      "m-0 whitespace-nowrap",
                      route.pathname + "/" == i.link.url && "text-main-red"
                    )}
                  >
                    {i.label}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
          <div className="hor-line mt-12 mb-12 line" />
          <ul className="flex flex-col w-full gap-y-10 px-4 second-menu">
            {story.header_terms.map((i: any) => (
              <li key={i._uid}>
                <Link href={i.link.url} legacyBehavior>
                  <a className="m-0 whitespace-nowrap text-xs text-main-neutrals">
                    {i.label}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
          <div className="hor-line mt-12 mb-12 line" />
          <div className="relative flex justify-center on-landscape">
            <button
              className={classNames(
                "m-0 text-main-red button text-base leading-none lg:w-[180px] w-[160px] login",
                showDropdown && "active"
              )}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              Login
            </button>
            {showDropdown && (
              <div className="dropdown">
                {story.header_login_url.map((i: any) => (
                  <Link href={i.link.url} legacyBehavior key={i._uid}>
                    <a target="_blank">
                      {i.label}
                      <SVGLinkArrow />
                    </a>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
