import Link from "next/link";
import React from "react";
import { useRouter } from "next/router";

export default function ApplyNow({
  applyNowText,
  className,
  onClick,
}: {
  applyNowText: string;
  className?: string;
  onClick?: any;
}) {
  const route = useRouter();

  return (
    <>
      {route.pathname !== "/vacancies" ? (
        <Link href="/vacancies" legacyBehavior>
          <a
            className={`button bg-main-red text-white md:font-medium font-normal ${className}`}
          >
            {applyNowText}
          </a>
        </Link>
      ) : (
        <>
          {onClick && (
            <button
              onClick={onClick}
              className={`button bg-main-red text-white md:font-medium font-normal flex items-center ${className}`}
            >
              {applyNowText}
            </button>
          )}
        </>
      )}
    </>
  );
}
