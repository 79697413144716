import Image from "next/image";
import React from "react";
import FooterNav from "@/components/Footer/FooterNav";
import Link from "next/link";
import process from "process";
import { useQuery } from "react-query";
import { queryKeys } from "@/queries/queryKeys";
import axios from "axios";
export interface FooterProps {
  applyNowText: string;
}
export default function Footer({ applyNowText }: FooterProps) {
  const url = `https://api-us.storyblok.com/v2/cdn/stories/footer?token=${process.env.NEXT_PUBLIC_STORYBLOK_API_KEY}&version=published`;
  const { data, isLoading } = useQuery(queryKeys.footer, () => axios.get(url));
  const story = data?.data?.story?.content;
  return (
    <footer className="lg:pt-10 md:pt-8 pt-2 relative">
      <div className="hor-line absolute top-0 left-0"></div>
      {!isLoading && (
        <div className="land-container">
          <div className="flex justify-between md:items-start items-center pb-6 md:flex-row flex-col">
            <div className="lg:w-10 w-12 overflow-hidden lg:mr-12 md:mr-4 md:ml-0 md:ml-6 m-auto">
              <Image
                src={story.logo}
                width={100}
                height={100}
                className="object-contain object-center w-full"
                alt="Logo"
              />
            </div>
            <div className="flex flex-col w-full h-full md:mt-0 mt-4">
              <FooterNav
                footerMenu={story.footer_menu_nav}
                joinUsLabel={story.join_us_label}
                applyNowText={applyNowText}
              />
            </div>
          </div>
          <div className="hor-line" />
          <div className="flex md:justify-between md:flex-row flex-col justify-center">
            <p className="md:py-6 py-2 text-main-neutrals text-base md:text-left text-center md:text-base text-sm">
              Copyright © 2023 Nitro Security. All rights reserved
            </p>
            <ul className="text-main-neutrals w-auto sm:space-x-6 flex flex-row items-center w-full md:justify-start justify-center">
              {story.footer_menu_terms.map((i: any) => (
                <li className="sm:mb-0 mb-4" key={i._uid}>
                  <Link href={i.link.url} legacyBehavior>
                    <a className="m-0 md:text-base text-sm">{i.label}</a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </footer>
  );
}
