import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Link from "next/link";
import ApplyNow from "@/components/Buttons/ApplyNow";
import classNames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SVGLinkArrow } from "@/images/svg/svg-icons";
import { useRouter } from "next/router";
export interface HeaderNavProps {
  story: any;
  applyNowText: string;
  isShowMenu: boolean;
  setIsShowMenu: Dispatch<SetStateAction<boolean>>;
  isHomepage: boolean;
  onServices: any;
}
export default function HeaderNav({
  story,
  applyNowText,
  isShowMenu,
  setIsShowMenu,
  isHomepage,
  onServices,
}: HeaderNavProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const actions = useRef<HTMLDivElement>(null);
  const route = useRouter();
  useEffect(() => {
    if (!showDropdown) return;
    function handleClick(event: any) {
      if (actions.current && !actions.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [showDropdown]);

  return (
    <div className="flex md:justify-between justify-end items-center w-full">
      <ul className="space-x-6 lg:ml-12 md:ml-6 items-center w-full md:flex hidden">
        <li key={1}>
          {!isHomepage ? (
            <a
              onClick={() => onServices()}
              className="m-0 whitespace-nowrap cursor-pointer"
            >
              Services
            </a>
          ) : (
            <AnchorLink
              href={`#services`}
              className="m-0 whitespace-nowrap cursor-pointer"
              offset={80}
            >
              Services
            </AnchorLink>
          )}
        </li>
        {story.header_nav.map((i: any) => (
          <li key={i._uid}>
            <Link href={i.link.url} legacyBehavior>
              <a
                className={classNames(
                  "m-0 whitespace-nowrap",
                  route.pathname + "/" == i.link.url && "text-main-red"
                )}
              >
                {i.label}
              </a>
            </Link>
          </li>
        ))}
      </ul>
      <div className="md:space-x-6 md:ml-6 flex items-center">
        <div className="relative on-mobile">
          <div ref={actions} className="md:flex hidden">
            <button
              className={classNames(
                "m-0 text-main-red button text-base leading-none lg:w-[180px] w-[160px] login",
                showDropdown && "active",
                route.pathname === "/vacancies" && "vacancies"
              )}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              Login
            </button>
          </div>
          {showDropdown && (
            <div className="dropdown">
              {story.header_login_url.map((i: any) => (
                <Link href={i.link.url} legacyBehavior key={i._uid}>
                  <a target="_blank">
                    {i.label}
                    <SVGLinkArrow />
                  </a>
                </Link>
              ))}
            </div>
          )}
        </div>

        <ApplyNow
          applyNowText={applyNowText}
          className={"w-full leading-none lg:w-[180px] w-[160px] header"}
        />
        <div
          className={classNames(
            "md:hidden flex flex-col gap-y-1 cursor-pointer p-6 pr-5",
            isShowMenu && "active-ntm-menu"
          )}
          onClick={() => setIsShowMenu(!isShowMenu)}
        >
          <i className="h-[3px] bg-main-black rounded-xl w-4 transition duration-150 ease-in-out" />
          <i className="h-[3px] bg-main-black rounded-xl w-4 transition duration-150 ease-in-out" />
        </div>
      </div>
    </div>
  );
}
