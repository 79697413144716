import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import ApplyNow from '@/components/Buttons/ApplyNow';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useRouter } from 'next/router';
export interface FooterNavProps {
  applyNowText: string;
  footerMenu: [];
  joinUsLabel: string;
}
export default function FooterNav({
  applyNowText,
  footerMenu,
  joinUsLabel,
}: FooterNavProps) {
  const route = useRouter();

  const [isHomepage, setIsHomepage] = useState(false);

  useEffect(() => {
    if (route.pathname === '/') {
      setIsHomepage(true);
    } else {
      setIsHomepage(false);
    }
  }, [route]);

  function onServices() {
    route.push('/').then(() => {});
    setTimeout(() => {
      const element = document.getElementById('services');
      if (element) {
        const targetElTop = element?.offsetTop;
        window.scrollTo({
          top: targetElTop - 79,
          behavior: 'smooth',
        });
      }
    }, 500);
  }
  return (
    <div className='flex justify-between items-start w-full md:flex-row flex-col'>
      <div className='flex items-center w-full flex-wrap'>
        {!isHomepage ? (
          <a
            onClick={() => onServices()}
            className='cursor-pointer md:m-0 lg:mr-[8%] md:mr-4 m-auto md:mb-0 mb-4 whitespace-nowrap md:w-auto w-[40%] md:text-left text-center md:p-4 p-2'
          >
            Services
          </a>
        ) : (
          <AnchorLink
            href={`#services`}
            className='md:m-0 lg:mr-[8%] md:mr-4 m-auto md:mb-0 mb-4 whitespace-nowrap md:w-auto w-[40%] md:text-left text-center md:p-4 p-2'
            offset='80'
          >
            Services
          </AnchorLink>
        )}

        {footerMenu?.map((i: any) => (
          <Link href={i.link.url} legacyBehavior key={i._uid}>
            <a className='md:m-0 lg:mr-[8%] md:mr-4 m-auto md:mb-0 mb-4 whitespace-nowrap md:w-auto w-[40%] md:text-left text-center md:p-4 p-2'>
              {i.label}
            </a>
          </Link>
        ))}
      </div>
      <div className='hor-line mb-6 mt-6 md:hidden' />
      <div className='md:ml-6 m-auto flex items-center min-h-[60px] md:flex-row flex-col mt-0 md:w-auto w-full justify-end'>
        <p className='md:text-main-red text-main-black text-base button cursor-default whitespace-nowrap flex items-center md:mr-8 md:mb-0 mb-2 w-auto'>
          {joinUsLabel}
        </p>
        <ApplyNow
          applyNowText={applyNowText}
          className={'md:w-[188px] w-full header'}
        />
      </div>
    </div>
  );
}
